@import "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap";
body {
  font-optical-sizing: auto;
  margin: 0;
  padding: 0;
  font-family: Raleway, sans-serif;
  font-style: normal;
  line-height: 1.5;
}

body .gallery {
  background-color: #000000e2;
  width: 100%;
  height: 100vh;
  display: none;
  position: fixed;
  top: 0;
}

body .gallery .close {
  cursor: pointer;
  position: absolute;
  top: 2%;
  right: 0%;
  transform: translate(0, -2%);
}

body .gallery .close img {
  width: 60%;
}

body .gallery .buttons {
  justify-content: space-between;
  width: 100%;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

body .gallery .buttons div {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

body .gallery .buttons div img {
  width: 80%;
}

body .gallery .image {
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

body .gallery .image img {
  width: 100%;
}

body .cart_section {
  z-index: 2;
  background: #fff;
  border-left: 1px solid gray;
  width: 18%;
  height: 100vh;
  transition: all .5s cubic-bezier(.075, .82, .165, 1);
  position: fixed;
  right: -20%;
}

body .cart_section .cart_container {
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  display: flex;
}

body .cart_section .cart_container .cart_heading {
  border-bottom: 1px solid #cecece;
  justify-content: space-between;
  align-items: center;
  height: 5vh;
  padding-top: 10px;
  display: flex;
}

body .cart_section .cart_container .cart_heading p, body .cart_section .cart_container .cart_heading h4 {
  cursor: pointer;
  margin: 0;
  line-height: 0;
}

body .cart_section .cart_container .cart_heading h4 {
  letter-spacing: .5px;
  font-size: .8rem;
}

body .cart_section .cart_container .cart_items_container {
  height: 95vh;
}

body .cart_section .cart_container .cart_items_container .items_container {
  width: 100%;
  height: 90%;
  overflow-y: auto;
}

body .cart_section .cart_container .cart_items_container .items_container .cart_section_items {
  border-bottom: 1px solid #cecece;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 5px 0;
  display: flex;
}

body .cart_section .cart_container .cart_items_container .items_container .cart_section_items .item_names {
  text-transform: capitalize;
}

body .cart_section .cart_container .cart_items_container .items_container .cart_section_items div:first-child {
  width: 40px;
  height: 60px;
  padding: 0 5px;
}

body .cart_section .cart_container .cart_items_container .items_container .cart_section_items div:first-child img {
  height: inherit;
  object-fit: contain;
  width: 100%;
}

body .cart_section .cart_container .cart_items_container .items_container .cart_section_items div:nth-child(2) {
  width: 40%;
  font-size: .7rem;
}

body .cart_section .cart_container .cart_items_container .items_container .cart_section_items div:nth-child(3) {
  align-items: center;
  display: flex;
}

body .cart_section .cart_container .cart_items_container .items_container .cart_section_items div:nth-child(3) span {
  cursor: pointer;
  border: 1px solid #cecece;
  place-items: center;
  height: 5px;
  margin: 0 7px;
  padding: 5px;
  line-height: 0;
  display: grid;
}

body .cart_section .cart_container .cart_items_container .items_container .cart_section_items div:nth-child(3) span:hover {
  color: #fff;
  background-color: #000;
}

body .cart_section .cart_container .cart_items_container .items_container .cart_section_items div:nth-child(3) p {
  margin: 0;
  font-size: .8rem;
}

body .cart_section .cart_container .cart_items_container .items_container .cart_section_items div:nth-child(4) {
  width: 15px;
}

body .cart_section .cart_container .cart_items_container .items_container .cart_section_items div:nth-child(4) img {
  width: 100%;
}

body .cart_section .cart_container .cart_items_container .items_container .cart_section_items .bin {
  cursor: pointer;
}

body .cart_section .cart_container .cart_items_container button {
  color: #fff;
  background-color: #000;
  border: none;
  width: 100%;
  margin-top: 20px;
  padding: 8px 0;
  font-size: .7rem;
}

body .banner {
  background-image: url("banner_background.fffd94d4.png");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 35vh;
  padding-top: 20px;
}

body .banner .container {
  height: inherit;
}

body .banner .container .banner_content {
  height: inherit;
  border-bottom: 1px solid #cecece;
  gap: 50px;
  padding-bottom: 15px;
  display: flex;
}

body .banner .container .banner_content .banner_caption, body .banner .container .banner_content .banner_corousel {
  height: inherit;
  width: 50%;
}

body .banner .container .banner_content .banner_caption p, body .banner .container .banner_content .banner_corousel p {
  font-size: .75rem;
}

body .banner .container .banner_content .banner_caption a, body .banner .container .banner_content .banner_corousel a {
  color: #000;
  text-decoration: none;
}

body .banner .container .banner_content .banner_caption a .about_us, body .banner .container .banner_content .banner_corousel a .about_us {
  cursor: pointer;
  cursor: pointer;
  border: 1px solid gray;
  border-radius: 15px;
  width: fit-content;
  margin-top: 20px;
  padding: 5px 15px;
  font-size: .7rem;
  font-weight: bold;
}

body .banner .container .banner_content .banner_caption a .about_us:hover, body .banner .container .banner_content .banner_corousel a .about_us:hover {
  color: #fff;
  background-color: #000;
}

body .banner .container .banner_content .banner_caption {
  align-items: center;
  display: flex;
}

body .banner .container .banner_content .banner_caption div h2 {
  margin-top: 0;
  font-family: Poppins, sans-serif;
  font-size: clamp(1.4rem, 1.6vw, 1.5rem);
}

body .banner .container .banner_content .banner_corousel {
  height: inherit;
  flex-direction: column;
  display: flex;
}

body .banner .container .banner_content .banner_corousel .corousel_trey {
  flex-grow: 15;
}

body .banner .container .banner_content .banner_corousel .corousel_trey .set1 {
  width: 100%;
  height: 100%;
  position: relative;
}

body .banner .container .banner_content .banner_corousel .corousel_trey .set1 img {
  opacity: 1;
  position: absolute;
}

body .banner .container .banner_content .banner_corousel .corousel_trey .set1 img:first-child {
  width: 25%;
  top: 40%;
  left: 0;
  transform: translateY(-40%);
}

body .banner .container .banner_content .banner_corousel .corousel_trey .set1 img:nth-child(2) {
  width: 60%;
  top: 0%;
  left: 60%;
  transform: translate(-60%);
}

body .banner .container .banner_content .banner_corousel .corousel_trey .set1 img:nth-child(3) {
  transform: -5%;
  width: 25%;
  right: 5%;
}

body .banner .container .banner_content .banner_corousel .corousel_trey .set1 img:nth-child(4) {
  width: 25%;
  bottom: 5%;
  left: 5%;
  transform: translate(-5%, -5%);
}

body .banner .container .banner_content .banner_corousel .corousel_trey .set1 img:nth-child(5) {
  width: 20%;
  bottom: 0;
  left: 55%;
  transform: translate(-55%);
}

body .banner .container .banner_content .banner_corousel .corousel_trey .set1 img:nth-child(6) {
  width: 25%;
  bottom: 5%;
  right: 2%;
  transform: translate(-5%);
}

body .banner .container .banner_content .banner_corousel .nav_circles {
  flex-grow: 1;
  place-items: center;
  display: grid;
}

body .banner .container .banner_content .banner_corousel .nav_circles .circle_holder {
  display: flex;
}

body .banner .container .banner_content .banner_corousel .nav_circles .circle_holder .circle {
  border: 1px solid gray;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  margin: 0 5px;
  padding: 1px;
}

body .banner .container .banner_content .banner_corousel .nav_circles .circle_holder .circle:first-child {
  background-color: gray;
}

body .container {
  width: 60%;
  margin: auto;
}

body .container nav {
  border-bottom: 1px solid #909090;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 15px;
  display: flex;
}

body .container nav a {
  color: #000;
  text-decoration: none;
}

body .container nav .logo, body .container nav .contact {
  align-items: center;
  gap: 15px;
  font-size: .7rem;
  display: flex;
}

body .container nav .logo img, body .container nav .contact img {
  width: 50px;
}

body .container nav .logo h4, body .container nav .contact h4 {
  margin: 0;
  font-size: .7rem;
}

body .container nav .logo p, body .container nav .contact p {
  cursor: pointer;
  border: 1px solid gray;
  border-radius: 15px;
  margin: 0;
  padding: 5px 15px;
  font-weight: bold;
}

body .container nav .logo p:hover, body .container nav .contact p:hover {
  color: #fff;
  background-color: #000;
}

body .container nav .logo .shopping_cart, body .container nav .contact .shopping_cart {
  cursor: pointer;
  width: 20px;
  position: relative;
}

body .container nav .logo .shopping_cart .items_number, body .container nav .contact .shopping_cart .items_number {
  background-color: orange;
  border-radius: 50%;
  place-items: center;
  width: 13px;
  height: 13px;
  display: grid;
  position: absolute;
  top: -5px;
  right: -8px;
}

body .container nav .logo .shopping_cart .items_number span, body .container nav .contact .shopping_cart .items_number span {
  box-sizing: border-box;
  font-weight: bold;
  line-height: 0;
}

body .container nav .logo .shopping_cart img, body .container nav .contact .shopping_cart img {
  width: 20px;
}

body .container nav .logo .mobile_menu, body .container nav .contact .mobile_menu {
  width: 30px;
  display: none;
}

body .container nav .logo .mobile_menu img, body .container nav .contact .mobile_menu img {
  width: 30px;
}

body .container .featured {
  cursor: pointer;
  border-bottom: 1px solid #cecece;
  margin-bottom: 50px;
  padding-bottom: 30px;
  position: relative;
}

body .container .featured .overlay {
  opacity: 0;
  z-index: 1;
  background: linear-gradient(to top, #0000, #0000006d 20%, #0000 80%);
  place-items: center;
  width: 100%;
  height: 100%;
  transition: all .5s;
  display: grid;
  position: absolute;
  top: 0;
}

body .container .featured .overlay button {
  cursor: pointer;
  border: 1px solid gray;
  border-radius: 25px;
  padding: 12px 25px;
  transition: transform .5s ease-out, background-color .3s ease-out;
}

body .container .featured .overlay button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #000;
}

body .container .featured h6 {
  padding-top: 10px;
}

body .container .featured h6 span {
  font-weight: 300;
}

body .container .featured .featured_products {
  justify-content: space-between;
}

body .container .featured .featured_products .featured_trey {
  gap: 10px;
  transition: all 1s ease-in-out;
  display: flex;
}

body .container .featured .featured_products .featured_trey div {
  border: 1px solid #cecece;
  border-radius: 10px;
  place-items: center;
  width: 40%;
  padding: 10px 0;
  display: grid;
}

body .container .featured .featured_products .featured_trey div img {
  width: 80%;
}

body .container .featured .featured_products .featured_trey div:first-child img {
  width: 60px;
}

body .container .featured .featured_products .featured_trey div:nth-child(5) img {
  width: 50%;
}

body .container .featured:hover .overlay {
  opacity: 1;
}

body .container .featured:hover .overlay button {
  transform: translateY(70%);
}

body .container .content_header {
  text-align: center;
  width: 60%;
  margin: 0 auto;
}

body .container .content_header .content h2 {
  font-family: Poppins, sans-serif;
}

body .container .content_header .content p {
  font-size: .8rem;
}

body .container main {
  background: url("content_background.4f234d91.png") center / 50% no-repeat;
  width: 90%;
  margin: 80px auto 0;
}

body .container main .pick_up_section {
  justify-content: center;
  align-items: center;
  display: flex;
}

body .container main .pick_up_section .flex {
  width: 50%;
}

body .container main .pick_up_section .flex:first-child img {
  width: 80%;
}

body .container main .pick_up_section .flex:nth-child(2) {
  width: 40%;
}

body .container main .pick_up_section .flex .content {
  align-items: center;
  display: flex;
}

body .container main .pick_up_section .flex .content .icon {
  width: 25px;
  margin-left: 15px;
  transform: scaleX(-1);
}

body .container main .pick_up_section .flex .content .icon img {
  width: 100%;
}

body .container main .pick_up_section .flex p {
  margin: 0;
  font-size: .8rem;
}

body .container main .delivery_section {
  justify-content: center;
  align-items: center;
  display: flex;
}

body .container main .delivery_section .flex {
  width: 50%;
}

body .container main .delivery_section .flex:first-child {
  width: 40%;
}

body .container main .delivery_section .flex:nth-child(2) {
  justify-content: flex-end;
  display: flex;
}

body .container main .delivery_section .flex:nth-child(2) img {
  width: 80%;
}

body .container main .delivery_section .flex .content {
  align-items: center;
  display: flex;
}

body .container main .delivery_section .flex .content .icon {
  width: 25px;
  margin-left: 15px;
  transform: scaleX(-1);
}

body .container main .delivery_section .flex .content .icon img {
  width: 100%;
}

body .container main .delivery_section .flex p {
  margin: 0;
  font-size: .8rem;
}

body .container .shop h2 {
  margin: 50px 0;
}

body .container .shop .items {
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  width: 100%;
  display: grid;
}

body .container .shop .items .store_items {
  width: 100%;
}

body .container .shop .items .store_items div {
  border: 1px solid #cecece;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 160px;
  display: flex;
  position: relative;
}

body .container .shop .items .store_items div .overlay {
  opacity: 0;
  background-color: #0000001c;
  place-items: center;
  width: 100%;
  height: 100%;
  transition: all .3s;
  display: grid;
  position: absolute;
}

body .container .shop .items .store_items div .overlay button {
  cursor: pointer;
  cursor: pointer;
  border: 1px solid gray;
  border-radius: 15px;
  padding: 5px 15px;
}

body .container .shop .items .store_items div .overlay button:hover {
  color: #fff;
  background-color: #000;
}

body .container .shop .items .store_items div:hover .overlay {
  opacity: 1;
}

body .container .shop .items .store_items div img {
  height: inherit;
  object-fit: contain;
  width: 60%;
}

body .container .shop .items .store_items .shopping_items_details {
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 50px;
  display: flex;
}

body .container .shop .items .store_items .shopping_items_details p {
  text-transform: capitalize;
  flex-grow: 1;
  font-size: .65rem;
}

body .container .shop .items .store_items .shopping_items_details .item_cart {
  cursor: pointer;
  place-items: center;
  width: 15px;
  display: grid;
}

body .container .shop .items .store_items .shopping_items_details .item_cart img {
  width: 15px;
}

body .contact_information {
  background: linear-gradient(to left, #fbc37f, #f3967e);
  margin-top: 100px;
  padding: 30px 0;
}

body .contact_information .contact_container {
  width: 65%;
  margin: 0 auto;
}

body .contact_information .contact_container .heading {
  text-align: center;
}

body .contact_information .contact_container .heading h3 {
  color: #521f1f;
  margin-top: 0;
}

body .contact_information .contact_container .content_flexbox {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

body .contact_information .contact_container .content_flexbox .flex {
  width: 50%;
  display: flex;
}

body .contact_information .contact_container .content_flexbox .flex .open_house_container {
  display: flex;
}

body .contact_information .contact_container .content_flexbox .flex .open_house_container div:first-child:first-child {
  place-items: center;
  width: 70%;
  display: grid;
}

body .contact_information .contact_container .content_flexbox .flex .open_house_container div:first-child:first-child img {
  width: 100%;
}

body .contact_information .contact_container .content_flexbox .flex .open_house_container div:nth-child(2) {
  align-items: center;
  display: flex;
}

body .contact_information .contact_container .content_flexbox .flex .open_house_container div:nth-child(2) .shop_icon {
  width: 40px;
}

body .contact_information .contact_container .content_flexbox .flex .open_house_container div:nth-child(2) .shop_icon img {
  width: 100%;
}

body .contact_information .contact_container .content_flexbox .flex .open_house_container div:nth-child(2) .content {
  flex-direction: column;
  align-items: flex-start;
  margin: 0 20px;
}

body .contact_information .contact_container .content_flexbox .flex .open_house_container div:nth-child(2) .content h5 {
  margin-top: 0;
  margin-bottom: 10px;
}

body .contact_information .contact_container .content_flexbox .flex .open_house_container div:nth-child(2) .content .open_hours {
  flex-wrap: wrap;
}

body .contact_information .contact_container .content_flexbox .flex .open_house_container div:nth-child(2) .content .open_hours p {
  width: 50%;
  margin: 0;
  font-size: .8rem;
}

body .contact_information .contact_container .content_flexbox .flex .store_location {
  border-left: 1px solid #fff;
  align-items: center;
  display: flex;
}

body .contact_information .contact_container .content_flexbox .flex .store_location .location_icon {
  width: 30px;
  margin: 0 20px;
}

body .contact_information .contact_container .content_flexbox .flex .store_location .location_icon img {
  width: 100%;
}

body .contact_information .contact_container .content_flexbox .flex .store_location .content {
  width: 70%;
}

body .contact_information .contact_container .content_flexbox .flex .store_location .content h5, body .contact_information .contact_container .content_flexbox .flex .store_location .content p {
  margin: 0;
}

body .contact_information .contact_container .content_flexbox .flex .store_location .content p {
  font-size: .8rem;
}

body .contact_information .contact_container .content_flexbox .flex .store_location .content h5 {
  margin-top: 0;
  margin-bottom: 10px;
}

body .contact_information .contact_container .content_flexbox .flex .contact_image {
  align-items: center;
  width: 200px;
  display: flex;
}

body .contact_information .contact_container .content_flexbox .flex .contact_image img {
  width: 100%;
}

body .maps {
  width: 100%;
  overflow: hidden;
}

body .maps iframe {
  width: inherit;
}

body .copyright_section {
  background-color: #000;
}

body .copyright_section .copyright_container {
  color: #fff;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  height: 7vh;
  margin: 0 auto;
  display: flex;
}

body .copyright_section .copyright_container p {
  margin: 0;
  font-size: .6rem;
}

body .copyright_section .copyright_container .contact_info {
  gap: 10px;
  display: flex;
}

body .copyright_section .copyright_container .contact_info button {
  color: #fff;
  background-color: #0000;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 5px 20px;
  font-size: .7rem;
}

body .banner_text {
  text-align: center;
  width: 60%;
  margin: 25px auto auto;
  padding-top: 10px;
}

body .banner_text p {
  font-size: 1rem;
  font-weight: bold;
}

body .about_container {
  width: 70%;
  margin: 0 auto;
}

body .about_description {
  height: max(350px, 30vh);
  margin-top: 25px;
  padding-top: 30px;
  display: flex;
}

body .about_description .about_image {
  background: url("gallery8.098831ab.jpeg") 0 0 / cover;
  border: 1px solid gray;
  width: 50%;
  margin-right: 30px;
}

body .about_description .about_textholder {
  justify-content: flex-end;
  align-items: center;
  width: 60%;
  display: flex;
}

body .about_description .about_textholder .about_text {
  width: 85%;
  line-height: 2;
}

body .about_description .about_textholder .about_text h4 {
  margin-bottom: 10px;
}

body .about_description .about_textholder .about_text p {
  font-size: clamp(.9rem, 1vw, .8rem);
}

body .about_description .about_textholder .carousel {
  justify-content: right;
  width: 100%;
  padding-top: 15px;
  display: flex;
}

body .about_description .about_textholder .carousel img {
  width: 25px;
  height: 25px;
}

body .about_description .about_textholder .carousel h6 {
  margin: 5px 20px 0;
}

body .about_description2 {
  flex-direction: row-reverse;
  height: max(350px, 30vh);
  margin-top: 50px;
  padding-top: 30px;
  display: flex;
}

body .about_description2 .about_image {
  background: url("gallery9.b7ff73da.jpeg") center / cover;
  border: 1px solid gray;
  width: 50%;
}

body .about_description2 .about_textholder {
  justify-content: space-between;
  align-items: center;
  width: 60%;
  display: flex;
}

body .about_description2 .about_textholder .about_text {
  width: 85%;
  line-height: 2;
}

body .about_description2 .about_textholder .about_text h4 {
  margin-bottom: 10px;
}

body .about_description2 .about_textholder .about_text p {
  font-size: clamp(.9rem, 1vw, .8rem);
}

body .about_description2 .about_textholder .carousel {
  justify-content: right;
  width: 100%;
  padding-top: 15px;
  display: flex;
}

body .about_description2 .about_textholder .carousel img {
  width: 25px;
  height: 25px;
}

body .about_description2 .about_textholder .carousel h6 {
  margin: 5px 20px 0;
}

body .shop_gallery_container {
  text-align: center;
  margin-top: 50px;
}

body .shop_gallery_container .shop_gallery {
  gap: 10px;
  display: flex;
}

body .shop_gallery_container .shop_gallery div {
  cursor: pointer;
  border: 1px solid #cecece;
  width: 16.6667%;
  height: max(150px, 15vh);
}

body .shop_gallery_container .shop_gallery div img {
  height: inherit;
  object-fit: cover;
  width: 100%;
}
/*# sourceMappingURL=index.dba77a21.css.map */
