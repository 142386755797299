@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

@mixin buttons {
    border: 1px solid gray;
    padding: 5px 15px;
    border-radius: 15px;
    cursor: pointer;
}


$border-gray: rgb(206, 206, 206);
$header-font: "Poppins", sans-serif;

body {
    margin: 0;
    line-height: 1.5;
    padding: 0;
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;

    //gallery styling begins here
    .gallery {
        background-color: rgba(0, 0, 0, 0.887);
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        display: none;

        .close {
            position: absolute;
            right: 0%;
            top: 2%;
            transform: translate(0, -2%);
            cursor: pointer;

            img {
                width: 60%;
            }
        }

        .buttons {
            width: 100%;
            display: flex;
            justify-content: space-between;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            div {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                img {

                    width: 80%;
                }
            }



        }

        .image {
            position: absolute;
            width: 40%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            img {
                width: 100%;
            }
        }
    }

    //cart side section
    .cart_section{
        position: fixed;
        right: -20%;
        width: 18%;
        height: 100vh;
        z-index: 2;
        background: white;
        border-left: 1px solid gray;
        transition: .5s cubic-bezier(0.075, 0.82, 0.165, 1);

        .cart_container{
            width: 90%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;

            .cart_heading{
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 5vh;
                //background-color: orange;
                padding-top: 10px;
                border-bottom: solid 1px $border-gray;
                
                p,h4{
                    margin: 0;
                    line-height: 0;
                    cursor: pointer;
                }

                h4{
                    font-size: .8rem;
                    letter-spacing: .5px;
                }

            }

            .cart_items_container{
                height: 95vh;
                //background-color: red;

                .items_container{
                    width: 100%;
                    height: 90%;
                    //background-color: limegreen;
                    overflow-y: auto;

                    .cart_section_items{
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        justify-content: space-between;
                        border-bottom: 1px solid $border-gray;
                        padding: 5px 0;

                        .item_names{
                            text-transform: capitalize;
                        }

                        div:nth-child(1){
                            width: 40px;
                            height: 60px;
                            padding: 0px 5px;
                            //border: solid 1px $border-gray;
                            img{
                                width: 100%;
                                height: inherit;
                                object-fit: contain;
                            }
                        }

                        div:nth-child(2){
                            
                          font-size: .7rem;
                          width: 40%;
                            
                        }

                        div:nth-child(3){
                            display: flex;
                            align-items:center;
                            span{
                                border: 1px solid $border-gray;
                                padding: 5px;
                                height: 5px;
                                line-height: 0;
                                margin: 0 7px;
                                display: grid;
                                place-items: center;
                                cursor: pointer;

                                &:hover{
                                    background-color: black;
                                    color: white;
                                }
                            }

                            p{
                                margin: 0;
                                font-size: .8rem;
                            }
                        }

                        div:nth-child(4){
                            width: 15px;
                            img{
                                width: 100%;
                            }
                        }

                        .bin{
                            cursor: pointer;
                        }
                    }
                }

                button{
                    width: 100%;
                    margin-top: 20px;
                    padding: 8px 0;
                    border: none;
                    background-color: black;
                    color: white;
                    font-size: .7rem;
                }
            }

            
        }
    }



    //Banner styling begins here...
    .banner {
        width: 100%;
        height: 35vh;
        background-image: url(../images/banner_background.png);
        background-size: cover;
        background-position: center;
        padding-top: 20px;

        .container {
            height: inherit;

            .banner_content {
                display: flex;
                height: inherit;
                gap: 50px;
                padding-bottom: 15px;
                border-bottom: 1px solid $border-gray ;

                .banner_caption,
                .banner_corousel {
                    width: 50%;
                    height: inherit;

                    p {
                        font-size: .75rem;
                    }

                    a {
                        text-decoration: none;
                        color: black;

                        .about_us {
                            width: fit-content;
                            font-size: .7rem;
                            @include buttons();
                            margin-top: 20px;
                            cursor: pointer;
                            font-weight: bold;

                            &:hover {
                                background-color: black;
                                color: white;
                            }
                        }
                    }



                }

                .banner_caption {
                    display: flex;
                    align-items: center;

                    div {
                        h2 {
                            margin-top: 0;
                            font-size: clamp(1.4rem, 1.6vw, 1.5rem);
                            font-family: "Poppins", sans-serif;
                        }
                    }
                }

                .banner_corousel {
                    display: flex;
                    flex-direction: column;
                    height: inherit;

                    .corousel_trey {
                        flex-grow: 15;

                        .set1 {
                            width: 100%;
                            height: 100%;
                            position: relative;

                            img {
                                position: absolute;
                                opacity: 1;

                                &:nth-child(1) {
                                    width: 25%;
                                    left: 0;
                                    top: 40%;
                                    transform: translateY(-40%)
                                }

                                &:nth-child(2) {
                                    width: 60%;
                                    left: 60%;
                                    top: 0%;
                                    transform: translate(-60%);
                                }

                                &:nth-child(3) {
                                    width: 25%;
                                    right: 5%;
                                    transform: (-5%);

                                }

                                &:nth-child(4) {
                                    width: 25%;
                                    bottom: 5%;
                                    left: 5%;
                                    transform: translate(-5%, -5%);

                                }

                                &:nth-child(5) {
                                    width: 20%;
                                    bottom: 0;
                                    left: 55%;
                                    transform: translate(-55%);

                                }

                                &:nth-child(6) {
                                    width: 25%;
                                    right: 2%;
                                    bottom: 5%;
                                    transform: translate(-5%);
                                }
                            }
                        }
                    }

                    .nav_circles {
                        flex-grow: 1;
                        display: grid;
                        place-items: center;

                        .circle_holder {
                            display: flex;

                            .circle {
                                width: 5px;
                                height: 5px;
                                border: 1px solid gray;
                                margin: 0px 5px;
                                border-radius: 50%;
                                padding: 1px;

                                &:nth-child(1) {
                                    background-color: gray;
                                }

                            }


                        }
                    }
                }


            }
        }


    }

    //Container styling begins here..

    .container {
        width: 60%;
        margin: auto;

        // Navigation styling begins here...
        nav {
            padding-top: 30px;
            display: flex;
            justify-content: space-between;
            //margin: 20px 0 0 0;
            padding-bottom: 15px;
            border-bottom: 1px solid rgb(144, 144, 144);

            a {
                text-decoration: none;
                color: black;
            }

            .logo,
            .contact {
                display: flex;
                align-items: center;
                gap: 15px;
                font-size: .7rem;

                img {
                    width: 50px;
                }

                h4 {
                    margin: 0;
                    font-size: .7rem;
                }

                p {
                    margin: 0;
                    border: 1px solid gray;
                    padding: 5px 15px;
                    border-radius: 15px;
                    cursor: pointer;
                    font-weight: bold;

                    &:hover {
                        background-color: black;
                        color: white;
                    }
                }

                .shopping_cart{
                    width: 20px;
                    position: relative;
                    cursor: pointer;

                    .items_number{
                        position: absolute;
                        right: -8px;
                        top: -5px;
                        background-color: orange;
                        width: 13px;
                        height: 13px;
                        display: grid;
                        place-items: center;
                        border-radius: 50%;

                        span{
                            box-sizing: border-box;
                            line-height: 0;
                            font-weight: bold;
                        }
                    }

                    img{
                        width: 20px;
                    }

                }

                .mobile_menu{
                    width: 30px;
                    display: none;

                    img{
                        width: 30px;
                    }
                }
            }


        }

        //featured products styling begins here...

        .featured {
            position: relative;
            border-bottom: solid 1px $border-gray;
            padding-bottom: 30px;
            margin-bottom: 50px;
            cursor: pointer;




            .overlay {
                position: absolute;
                top: 0;
                background: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.427) 20%, transparent 80%);
                width: 100%;
                height: 100%;
                display: grid;
                place-items: center;
                opacity: 0;
                transition: .5s;
                z-index: 1;


                button {
                    @include buttons();
                    border-radius: 25px;
                    padding: 12px 25px;
                    transition: transform .5s ease-out, background-color .3s ease-out;


                    &:hover {
                        background-color: black;
                        color: white;
                        cursor: pointer;
                    }

                }
            }

            h6 {
                padding-top: 10px;

                span {
                    font-weight: 300;
                }
            }

            .featured_products {

                justify-content: space-between;

                .featured_trey {
                    display: flex;
                    gap: 10px;
                    transition: 1s ease-in-out;

                    div {
                        border: solid 1px $border-gray;
                        width: 40%;
                        padding: 10px 0;
                        display: grid;
                        place-items: center;
                        border-radius: 10px;

                        img {
                            width: 80%;
                        }

                        &:nth-child(1) {
                            img {
                                width: 60px;
                            }
                        }

                        &:nth-child(5) {
                            img {
                                width: 50%;
                            }
                        }
                    }
                }


            }

            &:hover .overlay {
                opacity: 1;
            }

            &:hover .overlay button {
                transform: translateY(70%);
            }
        }

        // Content header styling begins here...
        .content_header {
            width: 60%;
            margin: 0 auto;
            text-align: center;

            .content {
                h2 {
                    font-family: $header-font;
                }

                p {
                    font-size: .8rem;
                }
            }
        }

        //Pick up and delivery section styling begins here...
        main {
            width: 90%;
            margin: 0 auto;
            background: url(../images/content_background.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 50%;
            margin-top: 80px;

            .pick_up_section {
                display: flex;
                align-items: center;
                justify-content: center;

                .flex {
                    width: 50%;

                    &:nth-child(1) {
                        img {
                            width: 80%;
                        }
                    }

                    &:nth-child(2) {
                        width: 40%;
                    }

                    .content {
                        display: flex;
                        align-items: center;

                        .icon {
                            width: 25px;
                            transform: scaleX(-1);
                            margin-left: 15px;

                            img {
                                width: 100%;
                            }
                        }
                    }

                    p {
                        font-size: .8rem;
                        margin: 0;
                    }
                }
            }

            .delivery_section {
                display: flex;
                align-items: center;

                justify-content: center;

                .flex {
                    width: 50%;

                    &:nth-child(1) {
                        width: 40%;
                    }

                    &:nth-child(2) {
                        display: flex;
                        justify-content: flex-end;

                        img {
                            width: 80%;
                        }
                    }

                    .content {
                        display: flex;
                        align-items: center;

                        .icon {
                            width: 25px;
                            transform: scaleX(-1);
                            margin-left: 15px;

                            img {
                                width: 100%;
                            }
                        }
                    }

                    p {
                        font-size: .8rem;
                        margin: 0;
                    }
                }

            }
        }

        //Shop styling begins here...
        .shop{
            h2{
                margin: 50px 0;
            }
            .items{
                width: 100%;
                // display: flex;
                // flex-wrap: wrap;
                // justify-content: space-evenly;
                // gap: max(10px,2%);

                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
                grid-gap: 15px;

                .store_items{
                    width: 100%;
                   
                    //height:  max(160px,5%);

                    div{
                    border:1px solid $border-gray;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height:  160px;
                    
                    position: relative;
                    

                    .overlay{
                        position: absolute;
                        background-color: rgba(0, 0, 0, 0.109);
                        width: 100%;
                        height: 100%;
                        display: grid;
                        place-items: center;
                        opacity: 0;
                        transition: .3s;

                        button{
                            @include buttons();
                            cursor: pointer;

                            &:hover{
                                background-color: black;
                                color: white;
                            }
                        }
                    }

                    &:hover .overlay{
                        opacity: 1;
                    }

                        img{
                            width: 60%;
                            height: inherit;
                            object-fit: contain;
                        }
                    }

                    .shopping_items_details{
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        width: 100%;
                        height: 50px;
                        p{
                            //text-align: center;
                            font-size: .65rem;
                            text-transform: capitalize;
                            flex-grow: 1;
                        }

                        .item_cart{
                            display: grid;
                            place-items: center;
                            width: 15px;
                            cursor: pointer;
                            img{
                                width: 15px;
                            }
                        }
                    }

                    

                    
                }
            }
        }



    }

    // contact information styling begins here..
    .contact_information {
        background: linear-gradient(to left, #FBC37F, #F3967E);
        padding: 30px 0;
        margin-top: 100px;

        .contact_container {
            width: 65%;
            margin: 0 auto;

            .heading {
                text-align: center;

                h3 {
                    margin-top: 0;
                    color: #521F1F;
                }
            }

            .content_flexbox {
                width: 100%;
                display: flex;
                justify-content: space-between;

                .flex {
                    display: flex;
                    width: 50%;

                    .open_house_container {
                        display: flex;


                        div {
                            &:nth-child(1) {
                                &:nth-child(1) {
                                    width: 70%;
                                    display: grid;
                                    place-items: center;

                                    img {
                                        width: 100%;
                                    }
                                }
                            }

                            &:nth-child(2) {
                                display: flex;
                                align-items: center;

                                .shop_icon {
                                    width: 40px;

                                    img {
                                        width: 100%;
                                    }
                                }

                                .content {
                                    flex-direction: column;
                                    align-items: flex-start;
                                    margin: 0 20px;

                                    h5 {
                                        margin-top: 0;
                                        margin-bottom: 10px;
                                    }

                                    .open_hours {
                                        flex-wrap: wrap;

                                        p {
                                            width: 50%;
                                            margin: 0;
                                            font-size: .8rem;
                                        }


                                    }
                                }
                            }

                        }

                    }

                    .store_location {
                        display: flex;
                        align-items: center;
                        border-left: solid 1px white;

                        .location_icon {
                            width: 30px;
                            margin: 0 20px;

                            img {
                                width: 100%;
                            }
                        }

                        .content {
                            width: 70%;

                            h5,
                            p {
                                margin: 0;
                            }

                            p {
                                font-size: .8rem;
                            }

                            h5 {
                                margin-top: 0;
                                margin-bottom: 10px;
                            }
                        }
                    }

                    .contact_image {
                        width: 200px;
                        display: flex;
                        align-items: center;

                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    //Map
    .maps {
        width: 100%;
        //height: 25dvh;
        overflow: hidden;

        iframe {
            width: inherit;
        }
    }

    //copyright
    .copyright_section {
        background-color: black;

        .copyright_container {
            width: 90%;
            margin: 0 auto;
            color: white;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 7vh;

            p {
                margin: 0;
                font-size: .6rem;
            }

            .contact_info {
                display: flex;
                gap: 10px;

                button {
                    border: none;
                    border: 1px solid white;
                    color: white;
                    background-color: transparent;
                    border-radius: 10px;
                    padding: 5px 20px;
                    font-size: .7rem;
                }
            }
        }
    }


    .banner_text {
        padding-top: 10px;
        width: 60%;
        margin: auto;
        margin-top: 25px;
        text-align: center;

        p {
            font-size: 1rem;
            font-weight: bold;
        }
    }


    // About styling begins here
    .about_container {
        width: 70%;
        margin: 0 auto;
    }

    .about_description {
        padding-top: 30px;
        display: flex;

        height: max(350px, 30vh);

        margin-top: 25px;

        .about_image {
            border: 1px solid grey;
            width: 50%;
            margin-right: 30px;
            background: url(../images/gallery/gallery8.jpeg);
            background-size: cover;
        }

        .about_textholder {
            width: 60%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .about_text {
                width: 85%;
                line-height: 2;

                h4 {
                    margin-bottom: 10px;
                }

                p {
                    font-size: clamp(.9rem, 1vw, .8rem);
                }
            }

            .carousel {
                padding-top: 15px;
                display: flex;
                justify-content: right;
                width: 100%;

                img {
                    width: 25px;
                    height: 25px;
                }

                h6 {
                    margin: 0;
                    margin-top: 5px;
                    margin-left: 20px;
                    margin-right: 20px;
                }
            }
        }
    }

    .about_description2 {
        padding-top: 30px;
        display: flex;
        flex-direction: row-reverse;

        height: max(350px, 30vh);
        margin-top: 50px;

        .about_image {
            border: 1px solid grey;
            width: 50%;
            background: url(../images/gallery/gallery9.jpeg);
            background-size: cover;
            background-position: center;
        }

        .about_textholder {
            width: 60%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .about_text {
                width: 85%;
                line-height: 2;

                h4 {
                    margin-bottom: 10px;
                }

                p {
                    font-size: clamp(.9rem, 1vw, .8rem);
                }
            }

            .carousel {
                padding-top: 15px;
                display: flex;
                justify-content: right;
                width: 100%;

                img {
                    width: 25px;
                    height: 25px;
                }

                h6 {
                    margin: 0;
                    margin-top: 5px;
                    margin-left: 20px;
                    margin-right: 20px;
                }
            }
        }
    }

    .shop_gallery_container {
        margin-top: 50px;
        text-align: center;



        .shop_gallery {
            display: flex;
            gap: 10px;

            div {
                width: calc(100%/6);
                height: max(150px, 15vh);
                border: solid 1px $border-gray;
                cursor: pointer;
                border: solid 1px $border-gray;

                img {

                    width: 100%;
                    height: inherit;
                    object-fit: cover;
                }
            }
        }
    }






}